import { initializeApp } from 'firebase/app';

export const firebaseApp = initializeApp({
    apiKey: 'AIzaSyBfBI7zpqYBilCEP_xZLL04zBicEQznqDo',
    authDomain: 'portfolio-af67b.firebaseapp.com',
    projectId: 'portfolio-af67b',
    storageBucket: 'portfolio-af67b.appspot.com',
    messagingSenderId: '644066987302',
    appId: '1:644066987302:web:3b200b5c0432a544fd45e7',
    measurementId: 'G-YM9WL2M0FD'
});
